
<!-- 首页 -->
<template>
  <div class="index">
    <!-- 地图 -->
    <div id="container" @click="half = false"></div>
    <!-- 全局音频 -->
    <audio :src="$global.ImgUrl + audioAll" ref="audioPlay"></audio>
    <!-- 左侧手绘地图开关 -->
    <div class="leftSwitch1">
      <div class="switch">
        <mt-switch v-model="isShowLelayer" @input="onLelayerInput"></mt-switch>
      </div>
      <p class="leftTitel">{{ this.lanData.tagName3 }}</p>
    </div>
    <div class="leftSwitch2">
      <div class="rightIcon" @click="toNav()">
        <img src="./image/icon_daolan21.svg" alt="" />
      </div>
      <p class="leftTitel">{{ this.lanData.tagName13 }}</p>
    </div>
    <!-- 右边选择 -->
    <div class="rightSelect1">
      <div class="rightContent">
        <div class="rightIcon" @click="showSearchPageFn()">
          <img src="./image/icon_search.svg" alt="" />
        </div>
        <p class="rightTitel">{{ this.lanData.tagName10 }}</p>
      </div>
      <div class="rightContent">
        <div class="rightIcon" @click="specialBtnFn()">
          <img src="./image/icon_zhuanti.svg" alt="" />
        </div>
        <p class="rightTitel">{{ this.lanData.tagName8 }}</p>
      </div>
      <div class="rightContent">
        <div class="rightIcon" @click="pathShowFn()">
          <img src="./image/icon_route (1).svg" alt="" />
        </div>
        <p class="rightTitel">{{ this.lanData.tagName11 }}</p>
      </div>
      <router-link to="/round" class="rightContent">
        <div class="rightIcon">
          <img src="./image/icon_near11.svg" alt="" />
        </div>
        <p class="rightTitel">{{ this.lanData.tagName12 }}</p>
      </router-link>
      <router-link to="/message" class="rightContent">
        <div class="rightIcon">
          <img src="./image/icon_near11.svg" alt="" />
        </div>
        <p class="rightTitel">{{ this.lanData.tagName14 }}</p>
      </router-link>
    </div>
    <!-- 右侧语言选择 -->
    <div class="rightSelected">
      <div class="allShow">
        <div class="selectLanguage">
          <div class="selectIcon" @click="changeLanguage()"></div>
        </div>
      </div>
      <div class="languageSelect" v-show="languageTagData">
        <div
          :class="[
            'selectContent',
            languageNum == index ? 'selectContent-active' : '',
          ]"
          v-for="(item, index) in languageData"
          :key="index"
          @click="languageNumFn(index, item.id)"
        >
          {{ item.name }}
        </div>
      </div>
    </div>
    <!-- 专题页面 -->
    <div class="wrapper" v-show="show">
      <div class="top">
        <div @click="show = false" class="leftIcon"></div>
        <div class="rightTitle"></div>
      </div>
      <div class="topRightImg"></div>
      <div class="rightBottomImg"></div>
      <div class="listAll">
        <mt-swipe :auto="4000">
          <mt-swipe-item v-for="(item, index) in titleData" :key="index">
            <img
              :src="$global.ImgUrl + item.cover"
              @click="wrapperListFn(index)"
            />
          </mt-swipe-item>
        </mt-swipe>
      </div>
    </div>
    <!-- 底部导航 -->
    <div class="buttomNav">
      <div class="iconTop1" @click="offHalf()" v-if="!half"></div>
      <div class="iconTop2" @click="changeNavHalf" v-else></div>
      <div class="navContent">
        <div
          class="navSelect"
          v-for="(item, index) in initData.webPoiTypeVos"
          :key="index"
          @click="changeTab(item.id, index)"
        >
          <div class="selAll">
            <div
              :class="[
                'icon-select',
                tabActive == index ? 'icon-select-active' : '',
              ]"
            >
              <img
                :src="
                  tabActive == index
                    ? $global.ImgUrl + item.listSelectIcon
                    : $global.ImgUrl + item.listIcon
                "
                alt=""
              />
            </div>
            <div :class="['navName', tabActive == index ? 'Tabactive' : '']">
              {{ item.name }}
            </div>
          </div>
        </div>
      </div>
      <div class="navDetail" v-show="half">
        <!-- 子顶部导航栏 -->
        <div class="topSonNav">
          <div
            class="topSonTab"
            v-for="(item, index) in initData.webCityyVos"
            :key="index"
            @click="changeTopTab(index, item.id)"
          >
            <p>{{ item.name }}</p>
            <div :class="[topSonTabActive == index ? 'lineTab' : '']"></div>
          </div>
        </div>
        <div class="sonNav">
          <!-- 左侧导航栏 -->
          <div class="leftAll">
            <div class="leftSonNav">
              <div
                :class="[
                  'leftSonTab',
                  leftSonTabActive == index ? 'leftActive' : '',
                ]"
                v-for="(item, index) in scenicGress.webTagVos"
                :key="index"
                @click="changeLeftTab(index, item.id)"
              >
                {{ item.name }}
              </div>
            </div>
          </div>
          <!-- 详情展示内容 -->
          <div class="detailContent" ref="detailContent">
            <div
              class="detailShow"
              v-for="(item, index) in detailList"
              :key="index"
            >
              <div class="detailImg">
                <img :src="$global.ImgUrl + item.cover" alt="" />
                <div
                  class="ipa"
                  v-show="item.audio"
                  @click="playAudioFn(index)"
                >
                  <div
                    class="icon-push-active"
                    v-if="playing && index == audioIndex"
                  ></div>
                  <div class="icon-push" v-else></div>
                </div>
              </div>
              <div class="detailText">
                <div class="textTitle">{{ item.name }}</div>
                <div class="textAddress">
                  <div class="icon_address"></div>
                  <span>{{ item.address }}</span>
                </div>
                <div class="textFunction">
                  <div class="choice1" @click="showDetialpage(item.id)">
                    {{ lanData.tagName }}
                  </div>
                  <div
                    class="choice1"
                    v-if="item.jump"
                    @click="toDaolanFn(item.jump, item.relationId)"
                  >
                    {{ lanData.tagName1 }}
                  </div>
                  <div
                    class="choice1"
                    v-else-if="item.relationId"
                    @click="toDaolanFn(item.jump, item.relationId)"
                  >
                    {{ lanData.tagName1 }}
                  </div>
                  <div class="choice2" @click="toNavigationFn(item)">
                    {{ lanData.tagName2 }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 路线 -->
    <div class="path" v-show="pathShow">
      <div class="pathContent">
        <div class="photoTitle"></div>
        <div class="lineContent">
          <div
            @click="navigationShowFn(index)"
            v-for="(item, index) in newlines"
            :key="index"
          >
            <div class="contentTitle">
              <span>{{ item.name }}</span>
              <div class="tox"></div>
            </div>
            <div class="contentLine">
              <div class="lineTitle">
                共计{{ item.pointNum }}个景区 | 预计{{ item.duration }}天
              </div>
              <div class="line">
                <div
                  class="detailLine"
                  v-for="(items, index) in item.linePointVos"
                  :key="index"
                >
                  <div class="linePoint" v-if="items.pointId">
                    {{ items.pointName }}
                    <span class="to" v-if="index + 1 < item.pointNum"></span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="closeWrapper" @click="pathShow = false">
        <img src="./image/icon_window_close.svg" alt="" />
      </div>
    </div>
    <!-- 路线导航 -->
    <div class="lineNavigation" v-show="navigationShow">
      <div class="navigationTab">
        <div class="closeNavigation" @click="navigationFn()"></div>
        <div
          :class="['tabName', tabNameNum == index ? 'tabNameActive' : '']"
          v-for="(item, index) in newlines"
          :key="index"
          @click="tabNameFn(index)"
        >
          {{ item.name }}
        </div>
      </div>
      <!-- 详情展示内容 -->
      <div class="navigationWindow">
        <div
          style="width: 100%; height: 7.8rem"
          v-for="(item, index) in markerDetailData"
          :key="index"
        >
          <div class="windowImg" @click="audioPlay()">
            <img :src="$global.ImgUrl + item.cover" alt="" />
            <audio :src="$global.ImgUrl + item.audio" id="audioPlayer"></audio>
            <div class="icon_audio" v-if="!playing"></div>
            <div class="icon_audio-active" v-else></div>
          </div>
          <div class="windowContent">
            <div class="windowTitle">{{ item.name }}</div>
            <div class="WindowBtn">
              <div class="navigationBtn1" @click="toNavigationFn(item)">
                {{ lanData.tagName2 }}
              </div>
              <div class="navigationBtn2" @click="showDetialpage(item.id)">
                {{ lanData.tagName }}
              </div>
            </div>
          </div>
        </div>
        <div class="line-bottom">
          <div class="line-pois" id="linePois">
            <div
              v-for="(item, index) in lineMarkerArrDatas"
              :key="index"
              class="line-poi-item"
              v-show="item.pointId"
            >
              <div
                @click="allLinePointFn(index, item.pointId)"
                v-if="item.pointId"
              >
                <div
                  :class="[
                    'line-item-index',
                    allLinePointNum == index ? 'line-item-index-active' : '',
                  ]"
                >
                  {{ index + 1 }}
                </div>
                <div
                  :class="[
                    'line-item-name',
                    allLinePointNum == index ? 'line-item-name-active' : '',
                  ]"
                >
                  {{ item.pointName }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 搜索页面 -->
    <div class="search" v-show="showSearchPage">
      <!-- 搜索框 -->
      <div class="search-box">
        <input v-model="keyword" type="text" />
        <div class="serchbtn" @click="onSearch()"></div>
        <span class="onCancle" @click="onCancle()">{{
          this.lanData.tagName6
        }}</span>
      </div>
      <!-- 搜索内容 -->
      <div class="searchContent" v-show="searchShow">
        <div
          class="sContent"
          v-for="(item, index) in this.searchData"
          :key="index"
        >
          <div class="scenicItem" @click="backPrev(item.id)">
            <div class="leftItem">
              <div
                class="itemIcon"
                v-for="(it, index) in item.webPoiTypeVos"
                :key="index"
              >
                <img :src="$global.ImgUrl + it.listIcon" alt="" />
              </div>
              <div class="itemName">{{ item.name }}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="noResult" v-show="noResultShow">
        {{ this.lanData.tagName4 }}
      </div>
      <div v-show="histroyContentShow">
        <!-- 历史框 -->
        <div class="histroy">
          <div class="leftName">{{ this.lanData.tagName5 }}</div>
          <div class="icon_del" @click="clearHistroy"></div>
        </div>
        <!-- 历史记录内容 -->
        <div class="histroyContent">
          <div
            class="histroyList"
            v-for="(item, index) in searchList"
            :key="index"
            @click="goSearchDetail(item)"
          >
            <span class="areaName">{{ item }}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="scenicDetail" v-show="showDetailPage">
      <!-- //显示内容 -->
      <div class="showContent">
        <!-- //头部内容 -->
        <div class="showTop">
          <div class="topTitle">
            <div class="titleName">{{ markerDetailDataer.name }}</div>
            <div
              class="titleBtn"
              v-if="markerDetailDataer.allview"
              @click="toVr(markerDetailDataer.allview)"
            >
              VR
            </div>
          </div>
          <div class="topAddress">
            <div class="icon-adrress"></div>
            <p class="ps">{{ markerDetailDataer.address }}</p>
          </div>
          <div class="closeBtn" @click="closeDetail()"></div>
        </div>
        <div class="detailCover">
          <div class="swiper">
            <mt-swipe :auto="4000">
              <mt-swipe-item
                v-for="(items, index) in markerDetailDataer.imgVos"
                :key="index"
              >
                <img
                  style="width: 100%; height: 100%"
                  :src="$global.ImgUrl + items.pic"
                  alt=""
                />
              </mt-swipe-item>
            </mt-swipe>
          </div>
        </div>
        <!-- 语音播报组件 -->
        <div class="intro" v-show="markerDetailDataer.audio">
          <div class="audioImg"></div>
          <div class="audio">
            <div class="audio-box">
              <audio
                :src="$global.ImgUrl + markerDetailDataer.audio"
                id="audioPlayer"
              ></audio>
              <!-- 音频播放模块 -->
              <div class="player-box">
                <div
                  class="audio-left flex center"
                  id="audioPlayer"
                  @click="audioPlay"
                >
                  <a v-if="!playing">
                    <img src="./image/icon_play(1).svg" alt="" />
                  </a>
                  <a v-else>
                    <img src="./image/push.svg" alt="" />
                  </a>
                </div>
                <div class="audio-right flex center column">
                  <!-- <p style="max-width: 536px;">Beta-B_Kan R. Gao.mp3</p> -->
                  <div
                    class="progress-bar-bg"
                    id="progressBarBg"
                    @click="progressBarBg"
                  >
                    <span
                      id="progressDot"
                      :style="'left:' + dotLeft + '%'"
                    ></span>
                    <div
                      class="progress-bar"
                      id="progressBar"
                      :style="'width:' + barWidth + '%'"
                    ></div>
                  </div>
                  <div class="audio-time">
                    <span class="audio-length-current" id="audioCurTime">{{
                      audioCurTime
                    }}</span
                    >/
                    <span class="audio-length-total">{{ duration }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="detailAll">
          <!-- 中间介绍 -->
          <div class="centerIntroduce">
            <div class="introText" v-html="markerDetailDataer.pointInfo"></div>
          </div>
        </div>
        <!-- 底部导航 -->
        <div class="introduceBtn">
          <div
            class="lanBtn"
            v-if="markerDetailDataer.relationId"
            @click="
              toDaolanFn(markerDetailDataer.jump, markerDetailDataer.relationId)
            "
          >
            <span class="icon-daolan"></span>
            <span>{{ lanData.tagName1 }}</span>
          </div>
          <div
            class="lanBtn"
            v-else-if="markerDetailDataer.jump"
            @click="
              toDaolanFn(markerDetailDataer.jump, markerDetailDataer.relationId)
            "
          >
            <span class="icon-daolan"></span>
            <span>{{ lanData.tagName1 }}</span>
          </div>
          <div class="hangBtn" @click="toNavigationFn(markerDetailDataer)">
            <span class="icon-daohang"></span>{{ lanData.tagName2 }}
          </div>
        </div>
      </div>
    </div>
    <div class="leftLocation" @click="location()">
      <img src="./image/icon_location.svg" alt="" />
    </div>
    <!-- 信息窗体组件 -->
    <infoWindow
      ref="infoWindow"
      v-show="activePoiData"
      :extData="activePoiData"
      @infoClose="qureyData"
      @showDetail="showDetialpage"
    ></infoWindow>
  </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import Vue from "vue";
import { swiper, swiperSlide } from "vue-awesome-swiper";
import "swiper/dist/css/swiper.css";
import { Switch, Swipe, SwipeItem, Indicator, MessageBox } from "mint-ui";
import infoWindow from "../../components/infoWindow/infoWindow.vue";
import wx from "../../assets/js/wx";
Vue.component(Swipe.name, Swipe, SwipeItem.name, SwipeItem);
import {
  indexApi,
  indexDetailApi,
  indexTitleApi,
  allLineApi,
  searchApi,
  languageDataApi,
  markerDetailApi,
  spEyeApi,
  lineEyeApi,
} from "../../request/api/index";
Vue.component(Switch.name, Switch);
let vm = null;
const delay = (function () {
  let timer = 0;
  return function (callback, ms) {
    clearTimeout(timer);
    timer = setTimeout(callback, ms);
  };
})();
export default {
  //import引入的组件需要注入到对象中才能使用
  components: {
    swiper,
    swiperSlide,
    infoWindow,
  },
  data() {
    //这里存放数据
    return {
      infoWindow: null, //信息窗体
      activePoiData: "", //选中点位的数据
      selectedId: 0,
      mapObj: null, //初始化地图
      cluster: null, //点聚合集合
      markers: [], //创建标记点
      markerArr: [], //标记点集合
      checked: true, //手绘地图的开关
      languageType: 1, //首页语言选择的初始id
      languageData: [], //语言数据
      lat: "",
      lng: "",
      languageNum: 0, //语言选择的初始下标
      poiTagId: 0,
      poiTypeId: 40,
      region: 0,
      show: false, //标题显示遮罩层
      pathShow: false, //路线显示详情
      showDetailPage: false, //marker显示详情
      lanData: [],
      manyLanData: [
        {
          tagName: "详情",
          tagName1: "导览",
          tagName2: "导航",
          tagName3: "手绘",
          tagName4: " 查不到相关数据，换个词试试~",
          tagName5: "历史记录",
          tagName6: "取消",
          tagName7: "首页",
          tagName8: "专题",
          tagName9: "列表详情",
          tagName10: "搜索",
          tagName11: "线路",
          tagName12: "周边",
          tagName13: "导览集合",
          tagName14: "资讯",
        },
        {
          tagName: "Detail",
          tagName1: "Tour",
          tagName2: "Navigation",
          tagName3: "HandPainted",
          tagName4: "Can't find relevant data, try another word ~",
          tagName5: "The historical record",
          tagName6: "Cancel",
          tagName7: "Home page",
          tagName8: "Project",
          tagName9: "List details",
          tagName10: "Search",
          tagName11: "Line",
          tagName12: "Round",
          tagName13: "Tour collection",
          tagName14: "Info",
        },
        {
          tagName: "자세한정보",
          tagName1: "깜짝놀",
          tagName2: "네비게이션",
          tagName3: "손으로",
          tagName4: "관련 수치를 찾을 수 없으니 단어를 바꾸어 보자",
          tagName5: "역사 기록",
          tagName6: "취소",
          tagName7: "첫 페이지",
          tagName8: "주제",
          tagName9: "목록 자세한 정보",
          tagName10: "검색",
          tagName11: "노선",
          tagName12: "주변",
          tagName13: "가이드 모음",
          tagName14: "정보",
        },
        {
          tagName: "详细",
          tagName1: "ガイドブック",
          tagName2: "カーナビ",
          tagName3: "(もうしで",
          tagName4: "データが出てこないから、言葉を変えてみる~",
          tagName5: "歴史の記録。",
          tagName6: "キャンセル",
          tagName7: "トップページ",
          tagName8: "特集",
          tagName9: "リストの詳細",
          tagName10: "検索",
          tagName11: "回線",
          tagName12: "周辺の",
          tagName13: "ガイドセット",
          tagName14: "资讯",
        },
      ],
      //轮播图
      carouselList: [],
      active: 0,
      carouselIndex: 0,
      // 异形轮播图配置
      swiperOption: {
        effect: "coverflow",
        coverflowEffect: {
          rotate: 0, // slide做3d旋转时Y轴的旋转角度 (默认50).
          stretch: -30, // 每个slide之间的拉伸值 越大slide靠得越紧.
          depth: 100, // slide的位置深度 值越大z轴距离越远 看起来越小.
          modifier: 1, // depth和rotate和stretch的倍率 相当于(depth*modifier、rotate*modifier、stretch*modifier) 值越大这三个参数的效果越明显(默认1).
          slideShadows: false, // 开启slide阴影 (默认 true)
        },
        // 默认选中中间一张
        centeredSlides: true,
        // 无限循环
        loop: true,
        slideToClickedSlide: true,
        on: {
          slideChange: function () {
            // console.log(this.realIndex);
            vm.carouselIndex = this.realIndex;
          },
        },
      },
      tabActive: 0, //底部导航切换
      mapPostionData: [], //地图中心点位数据
      initData: [], //底部导航数据
      detailData: [], //底部导航详情内容数据
      pid: "", //marker的id
      markerDetailData: [], //点位详情数据
      markerDetailDataer: [],
      scenicGress: [], //景区级别数据
      allLineData: [], //全域路线数据
      allLineSelectData: [], //全域路线数据细分
      polyline: null, //路线的全局变量
      titleData: [], //标题数据
      geoMarkerIcon: [], //全域景区点图标
      topSonTabActive: 0, //顶部子导航切换
      leftSonTabActive: 0, //左侧子导航切换
      half: false, //底部半部分导航
      value: true, //switch的初始值
      tabNameNum: 0,
      allLinePointNum: 0,
      navigationShow: false, //关闭路线导航
      keyword: "", //搜索框输入的内容
      searchList: [], //搜索历史列表
      searchData: [], //搜索的数据
      searchShow: false, //显示搜索的内容
      noResultShow: false, //搜索不到结果
      iconShow: true, //显示历史记录的图标
      showSearchPage: false, //搜索页面的显示
      histroyContentShow: true, //历史记录的显示
      // 音频播放
      duration: "00:00", //音频总时长
      audioCurTime: "00:00", //音频当前时间
      playing: false, //音频播放中的状态
      barWidth: 0, //已播放条的宽度值
      dotLeft: 0, //进度点的left值
      leLayer: null, //瓦片
      isShowLelayer: true, //是否展示瓦片
      activeMarker: null, //线路当前点击的点位
      resLocation: [], //自身点位的坐标
      detailList: [], //底部列表详情数据
      audioAll: "", //全局音频
      audioIndex: -1, //全局音频的初始下标
      languageTagData: false, //标签语言
      curAudio: -1,
      newlines: null,
      lineMarkerArrData: null, //线路总数据
      lineMarkerArrDatas: null, //线路总数据
      //详情页底部显示
      detailAllShow: false,
      specialLineData: null, //专题线路数据
      specialIntroShow: false,
      titleId: "", //专题id
      lineId: "", //线路id
    };
  },
  created() {
    vm = this;
  },
  //监听属性 类似于data概念
  computed: {
    swiper() {
      return this.$refs.mySwiper.swiper;
    },
  },
  //监控data中的数据变化
  watch: {
    keyword() {
      delay(() => {
        const value = this.keyword.trim();
        if (!value) {
          //判断输入框的值是否为空  如果为空 直接返回不在执行
          this.searchShow = false;
          this.histroyContentShow = true;
          this.noResultShow = false;
        } else {
          //如果local里不存在当前输入的关键词 则存到local里
          if (this.searchList.indexOf(value) === -1) {
            this.searchList.unshift(value);
            //历史记录最多只取十个
            this.searchList = this.searchList.slice(0, 10);
            localStorage.setItem("keyword", JSON.stringify(this.searchList));
          }
          this.getSearchData(); //获取搜索的内容
        }
      }, 300);
    },
  },
  //方法集合
  methods: {
    //初始化地图
    initMap() {
      this.mapObj = new AMap.Map("container", {
        view: new AMap.View2D({
          // 创建地图二维视口
          center: new AMap.LngLat(
            this.mapPostionData.longitude,
            this.mapPostionData.latitude
          ),
          zoom: this.mapPostionData.showZoom, //设置地图初始的的缩放级别
          // zoom: 9, //设置地图初始的的缩放级别
          rotation: 0, //设置地图旋转角度
        }),
        // zooms: [9, 23], //地图可缩放的级别
        zooms: [this.mapPostionData.minZoom, this.mapPostionData.maxZoom], //地图可缩放的级别
        lang: "zh_cn", //设置地图语言类型，默认：中文简体
        features: ["bg", "road", "building"], //去除高德的瓦片
      });
      // 瓦片加载
      this.leLayer = new AMap.TileLayer({
        // 图块取图地址,加了s=1就是下沙景区限制
        tileUrl: "https://dydl.zysxh5.cn/upload_resource/57814/[z]/[x]_[y].png",
        // tileUrl: "http://tiles.worldmaipu.com/global/57814/[z]/[x]_[y].png",
        // tileUrl: "http://192.168.1.250:88/global/57814/[z]/[x]_[y].png",
        zIndex: 10,
      });
      // console.log(this.leLayer);
      this.leLayer.setMap(this.mapObj);
      if (this.navigationShow == false) {
        this.markerArr = this.detailList;
        this.markers = [];
        this.createMarker();
        this.mapObj.add(this.markers);
        // this.addCluster();
      } else if (this.navigationShow == true) {
        this.createLineMarker();
      }
      this.createLocation();
    },
    // 瓦片异步控制开关
    onLelayerInput(checked) {
      this.checked = checked;
      if (checked) {
        this.leLayer.setMap(this.mapObj);
      } else {
        this.leLayer.setMap(null);
      }
    },
    //创建marker点位
    createMarker() {
      let that = this;
      // console.log(markerArr);
      let markerIcon = this.scenicGress;
      // console.log(markerIcon);
      for (let i = 0; i < this.markerArr.length; i++) {
        if (this.markerArr[i].tagName) {
          if (
            this.markerArr[i].tagName == "2A" ||
            this.markerArr[i].tagName == "3A"
          ) {
            var markerContent =
              "" +
              '<div class="custom-content-marker">' +
              `<div class="contain-marker1">${this.markerArr[i].tagName}</div>` +
              "</div>";
          } else {
            var markerContent =
              "" +
              '<div class="custom-content-marker">' +
              `<div class="contain-marker2">${this.markerArr[i].tagName}</div>` +
              "</div>";
          }
          let marker = new AMap.Marker({
            position: new AMap.LngLat(
              this.markerArr[i].longitude,
              this.markerArr[i].latitude
            ), //marker点的位置
            content: markerContent,
            offset: new AMap.Pixel(-13, -30), //marker内容的偏移量
            extData: {
              pioData: this.markerArr[i],
              markerContent: markerContent,
            },
          });
          //标记点的文本内容
          marker.setLabel({
            offset: new AMap.Pixel(0, 20), //设置文本标注偏移量
            content: this.markerArr[i].name, //设置文本标注内容
            direction: "center", //设置文本标注方位
          });
          // marker点击监听事件
          AMap.event.addListener(marker, "click", function (e) {
            that.markerClick(this);
          });
          // this.mapObj.add(marker);
          this.markers.push(marker); //添加marker到地图
        } else {
          var markIcon;
          markIcon = new AMap.Icon({
            // 图标尺寸
            size: new AMap.Size(32, 32),
            // 图标的取图地址
            image: this.$global.ImgUrl + markerIcon.markIcon,
            // 图标所用图片大小
            imageSize: new AMap.Size(32, 32),
            // 图标取图偏移量
            // imageOffset: new AMap.Pixel(-9, -3),
          });
          let marker = new AMap.Marker({
            position: new AMap.LngLat(
              this.markerArr[i].longitude,
              this.markerArr[i].latitude
            ), //marker点的位置
            icon: markIcon,
            // content: '<div class="contain">1</div>',
            offset: new AMap.Pixel(-13, -30), //marker内容的偏移量
            extData: {
              pioData: this.markerArr[i],
            },
          });
          //标记点的文本内容
          marker.setLabel({
            offset: new AMap.Pixel(0, 30), //设置文本标注偏移量
            content: this.markerArr[i].name, //设置文本标注内容
            direction: "center", //设置文本标注方位
          });
          // marker点击监听事件
          AMap.event.addListener(marker, "click", function (e) {
            that.markerClick(this);
          });
          // this.mapObj.add(marker);
          this.markers.push(marker); //添加marker到地图
        }
      }
    },
    //创建路线marker点位
    createLineMarker() {
      let that = this;
      let arr = this.allLineSelectData.linePointVos;
      this.allLineSelectData.lineMarkerArr = [];
      let arrs = [];
      for (var i in arr) {
        if (arr[i].pointId) {
          // 点标记显示内容，HTML要素字符串
          arrs.push(arr[i]);
          for (var j in arrs) {
            var markerContent =
              "" +
              '<div class="custom-content-marker">' +
              `<div class="contain">${parseInt(j) + 1}</div>` +
              "</div>";
            var markerSelectContent =
              "" +
              '<div class="custom-content-marker">' +
              `<div class="contain-active">${parseInt(j) + 1}</div>` +
              "</div>";
          }
          let marker = new AMap.Marker({
            position: new AMap.LngLat(arr[i].longitude, arr[i].latitude), //marker点的位置
            content: markerContent,
            offset: new AMap.Pixel(-20, -50), //marker内容的偏移量
            extData: {
              pioData: arr[i],
              markerContent: markerContent,
              markerSelectContent: markerSelectContent,
            },
          });
          //标记点的文本内容
          marker.setLabel({
            offset: new AMap.Pixel(0, 40), //设置文本标注偏移量
            content: arr[i].pointName, //设置文本标注内容
            direction: "center", //设置文本标注方位
          });
          // marker点击监听事件
          AMap.event.addListener(marker, "click", function (e) {
            that.markerLineClick(this);
          });
          this.allLineSelectData.lineMarkerArr.push(marker);
          this.mapObj.add(marker); //添加marker到地图
        }
      }
    },

    // 关闭信息窗口
    qureyData() {
      if (this.infoWindow) {
        this.mapObj.remove(this.infoWindow);
        this.infoWindow = null;
        this.activePoiData = "";
      }
    },
    showDetialpage(id) {
      this.changeMarkAudioPlay(); //音乐的初始数据
      let audio = this.$refs.audioPlay;
      audio.pause();
      this.playing = false;
      this.showDetailPage = true;
      this.half = false;
      this.pid = id;
      this.getMarkerDetailDataer();
    },
    //创建信息窗体
    createInfoWindow(pio) {
      let that = this;
      this.infoWindow = new AMap.InfoWindow({
        position: [pio.longitude, pio.latitude],
        isCustom: true, //使用自定义窗体
        offset: new AMap.Pixel(0, -42),
        content: that.$refs.infoWindow.$el,
      });
      this.infoWindow.open(this.mapObj);
    },
    closeDetail() {
      this.showDetailPage = false;
      this.changeMarkAudioPlay();
    },
    toVr(e) {
      window.location.href = e;
    },
    //marker详情页数据
    getMarkerDetailDataer() {
      markerDetailApi({
        languageType: this.languageType,
        pid: this.pid,
      }).then((res) => {
        // console.log(res);
        if (res.status == "200") {
          this.markerDetailDataer = res.data.data[0];
          console.log(this.markerDetailDataer);
        }
      });
    },
    // 点位改成 点击之前的样式
    markerSetNomal(marker) {
      if (this.activeMarker) {
        marker.setContent(marker.getExtData().markerContent);
      }
    },
    // 点位改成 点击之后的样式
    markerSetClicked(marker) {
      if (this.activeMarker) {
        marker.setContent(marker.getExtData().markerSelectContent);
      }
    },
    //点击marker
    markerClick(e) {
      let audio = this.$refs.audioPlay;
      audio.pause();
      this.playing = false;
      console.log(e.getExtData());
      this.createInfoWindow(e.getExtData().pioData);
      this.activePoiData = e.getExtData().pioData;
      this.mapObj.setCenter(e.getPosition());
      this.pid = e.getExtData().pioData.id;
      // if (e.getExtData().pioData.relationId || e.getExtData().pioData.jump) {
      //   if (e.getExtData().pioData.jump) {
      //     window.location.href = e.getExtData().pioData.jump;
      //   } else {
      //     if (e.getExtData().pioData.relationId) {
      //       this.$router.push("/visitors/" + e.getExtData().pioData.relationId);
      //     } else {
      //       return;
      //     }
      //   }
      // } else {
      //   this.$router.push(
      //     "/scenicDetail/" +
      //     this.pid +
      //     "/" +
      //     this.languageType +
      //     "/" +
      //     this.resLocation.lat +
      //     "/" +
      //     this.resLocation.lng
      //   );
      // }
    },
    //点击线路的的点标记
    markerLineClick(e) {
      this.mapObj.setCenter(e.getPosition());
      // console.log(e.getExtData().pioData);
      if (this.activeMarker) {
        if (
          e.getExtData().pioData.id == this.activeMarker.getExtData().pioData.id
          // 如果已经有点被点击且 已经被点击的点和这次点击的点相同
        ) {
          return;
        } else {
          this.markerSetNomal(this.activeMarker);
        }
      }
      e.setContent(e.getExtData().markerSelectContent);
      this.activeMarker = e;
      // console.log(this.allLinePointNum);
      this.pid = e.getExtData().pioData.pointId;
      this.getMarkerDetailData();
      for (var j in this.newlines) {
        for (var i in this.newlines[j].linePointVos) {
          if (
            this.newlines[j].linePointVos[i].id == e.getExtData().pioData.id
          ) {
            this.allLinePointNum = i;
            // console.log(i);
          }
        }
      }
    },
    //marker详情页数据
    getMarkerDetailData() {
      Indicator.open({
        text: "Loading...",
      });
      markerDetailApi({
        languageType: this.languageType,
        pid: this.pid,
      }).then((res) => {
        // console.log(res);
        if (res.status == "200") {
          this.markerDetailData = res.data.data;
          // console.log(this.markerDetailData);
        }
        Indicator.close();
      });
    },
    // //添加点聚合
    // addCluster() {
    //   let that = this;
    //   this.cluster = new AMap.MarkerClusterer(this.mapObj, this.markers, {
    //     gridSize: 80,
    //     renderClusterMarker: that._renderClusterMarker,
    //     zoomOnClick: true,
    //   });
    // },
    // //点聚合的样式
    // _renderClusterMarker(context) {
    //   context.marker.setOffset(new AMap.Pixel(-20, -20));
    //   context.marker.setContent(`<div class="contain">${context.count}</div>`);
    // },
    //切换语言
    languageNumFn(index, languageType) {
      this.languageTagData = false;
      this.languageNum = index;
      this.languageType = languageType;
      this.getIndexDetailData();
      this.getMarkerDetailData();
      this.getInitData();
      this.getTitleData();
      this.getAllLineData();
      this.getSearchData();
      this.lanData = this.manyLanData[this.languageType - 1];
    },
    //首页
    indexBtnFn() {
      this.$router.replace("/bank");
    },
    //专题
    specialBtnFn() {
      this.qureyData();
      this.pathShow = false;
      this.bottomNavCon = false;
      this.show = true;
    },
    //点击专题路线
    wrapperListFn(index) {
      console.log(index);
      if (index == 1) {
        window.location.href = "https://story.zysxh5.cn/";
      } else {
        this.$router.push("/special/" + index + "/" + this.languageType);
      }
      this.titleId = this.titleData[index].id;
      spEyeApi({
        titleId: this.titleId,
      }).then((res) => {});
    },
    toNav() {
      this.$router.push("/nav");
    },
    offHalf() {
      this.half = true;
      this.qureyData();
    },
    //底部导航顶部切换
    changeNavHalf() {
      this.half = false;
    },
    //底部导航切换
    changeTab(id, index) {
      this.audioIndex = -1;
      this.curAudio = -1;
      let audio = this.$refs.audioPlay;
      audio.pause();
      // this.playing = false;
      this.audioEnded();
      this.audioInit();
      this.tabActive = index;
      this.scenicGress = this.initData.webPoiTypeVos.find(
        (item) => item.id == id
      );
      this.index = 0;
      this.leftSonTabActive = 0;
      this.poiTypeId = id;
      this.poiTagId = 0;
      if (this.poiTypeId == "40") {
        this.getIndexDetailDatas();
      } else {
        this.getIndexDetailData();
      }
    },
    //子顶部导航切换
    changeTopTab(index, id) {
      this.topSonTabActive = index;
      this.region = id;
      this.getIndexDetailData();
      let audio = this.$refs.audioPlay;
      audio.pause();
      // this.playing = false;
      this.audioEnded();
      this.audioInit();
    },
    //子左部导航切换
    changeLeftTab(index, id) {
      this.leftSonTabActive = index;
      this.poiTagId = id;
      if (this.poiTagId == "0") {
        this.getIndexDetailDatas();
      } else {
        this.getIndexDetailData();
      }
    },
    //跳转到导览
    toDaolanFn(jump, relationId) {
      if (jump) {
        window.location.href = jump;
      } else {
        if (relationId) {
          this.$router.push("/visitors/" + relationId);
        } else {
          return;
        }
      }
    },
    // 点击路线
    pathShowFn() {
      this.qureyData();
      let audio = this.$refs.audioPlay;
      audio.pause();
      this.half = false;
      this.playing = false;
      this.show = false;
      this.specialIntroShow = false;
      // this.changeMarkAudioPlay();
      this.pathShow = true;
      if (this.navigationShow == true) {
        this.navigationShow = false;
      }
    },
    //点击显示路线导航
    navigationShowFn(index) {
      this.lineId = this.newlines[index].id;
      lineEyeApi({
        lineId: this.lineId,
      }).then((res) => {
        console.log(res);
      });
      this.getAllLineData(); //全域景区路线
      this.pathShow = false;
      this.navigationShow = true;
      this.tabNameFn(index);
      this.lineMarkerArrData = this.allLineSelectData.linePointVos;
      this.initMap();
      this.lineArrData(this.lineMarkerArrData);
    },
    //点击切换路线导航
    tabNameFn(index) {
      this.tabNameNum = index;
      this.allLinePointNum = -1;
      this.allLineSelectData = this.allLineData[index];
      this.pid = this.newlines[index].linePointVos[0].pointId;
      this.lineMarkerArrDatas = this.newlines[index].linePointVos;
      this.lineMarkerArrData = this.allLineSelectData.linePointVos;
      this.initMap();
      this.lineArrData(this.lineMarkerArrData);
      // console.log(this.allLineSelectData);
      this.getMarkerDetailData();
      this.changeMarkAudioPlay(); //音乐的初始数据
    },
    //线路的线的数据处理
    lineArrData(data) {
      var arr = [];
      for (var i in data) {
        var arr1 = [data[i].longitude, data[i].latitude];
        arr.push(arr1);
      }
      this.polineShow(arr);
    },
    //线路显示
    polineShow(arr) {
      // console.log(arr);
      this.polyline = new AMap.Polyline({
        path: arr,
        isOutline: true,
        outlineColor: "#ffeeff",
        borderWeight: 1,
        strokeColor: "#3366FF",
        strokeOpacity: 1,
        strokeWeight: 3,
        // 折线样式还支持 'dashed'
        strokeStyle: "solid",
        // strokeStyle是dashed时有效
        strokeDasharray: [10, 5],
        lineJoin: "round",
        lineCap: "round",
        zIndex: 700,
      });
      this.polyline.setMap(this.mapObj);
      // 缩放地图到合适的视野级别
      this.mapObj.setFitView([this.polyline]);
    },
    //点击路线导航中的marker
    allLinePointFn(index, id) {
      this.allLinePointNum = index;
      this.pid = id;
      this.allLineSelectData = this.allLineData[this.tabNameNum];
      this.createLineMarker();
      this.getMarkerDetailData();
      let marker = this.allLineData[this.tabNameNum].lineMarkerArr[index];
      // console.log(marker);
      this.markerLineClick(marker);
      this.changeMarkAudioPlay(); //音乐的初始数据
    },
    navigationFn() {
      this.changeMarkAudioPlay();
      this.navigationShow = false;
      this.initMap();
    },
    //切换语言
    changeLanguage() {
      if (this.languageTagData == false) {
        this.languageTagData = true;
      } else {
        this.languageTagData = false;
      }
    },
    //创建定位点
    createLocation() {
      let lng1 = localStorage.getItem("lng");
      let lat1 = localStorage.getItem("lat");
      if (lng1) {
        let marker = new AMap.Marker({
          position: new AMap.LngLat(lng1, lat1), //marker点的位置
          content: "<div class='curMarker'></div>",
          offset: new AMap.Pixel(-13, -30), //marker内容的偏移量
        });
        this.mapObj.add(marker);
      }
    },
    location() {
      let that = this;
      that.wechat.wx.getLocation({
        type: "gcj02", // 默认为 wgs84 返回 gps 坐标，gcj02 返回可用于 wx.openLocation 的坐标
        success: function (res) {
          console.log("获取微信定位成功！");
          that.lat = res.latitude;
          that.lng = res.longitude;
          localStorage.setItem("lat", res.latitude);
          localStorage.setItem("lng", res.longitude);
          if (that.lat) {
            that.getInitData();
          }
        },
      });
      setTimeout(() => {
        that.mapObj.setCenter([that.lng, that.lat]);
      }, 100);
    },
    //点击详情页
    // showDetialPage(id) {
    //   this.pid = id;
    //   this.$router.push(
    //     "/scenicDetail/" +
    //     this.pid +
    //     "/" +
    //     this.languageType +
    //     "/" +
    //     this.resLocation.lat +
    //     "/" +
    //     this.resLocation.lng
    //   );
    // },
    //显示搜索页面
    showSearchPageFn() {
      this.showSearchPage = true;
      this.searchShow = false;
      this.qureyData();
    },
    //搜索
    onSearch() {
      Indicator.open(); //加载中
      const value = this.keyword.trim();
      if (!value) {
        //判断输入框的值是否为空  如果为空 直接返回不在执行
        this.searchShow = false;
        this.histroyContentShow = true;
      }
      //如果local里不存在当前输入的关键词 则存到local里
      if (this.searchList.indexOf(value) === -1) {
        this.searchList.unshift(value);
        //历史记录最多只取十个
        this.searchList = this.searchList.slice(0, 10);
        localStorage.setItem("keyword", JSON.stringify(this.searchList));
      }
      this.getSearchData(); //获取搜索的内容
    },
    //取消
    onCancle() {
      // this.showSearchPage = false;
      this.$router.replace({
        path: "/bank",
      });
    },
    // 清楚历史记录
    clearHistroy() {
      MessageBox({
        $type: "confirm",
        message: "是否确定清除历史记录?",
        title: "提示",
        showCancelButton: true,
      })
        .then((res) => {
          console.log(res);
          if (res == "confirm") {
            localStorage.removeItem("keyword"); // 清除本地存储的数据
            this.searchList = []; //data中的historyList 清除
          }
        })
        .catch((err) => {
          return;
        });
    },
    //请求全域搜索的数据
    getSearchData() {
      searchApi({
        languageType: this.languageType,
        name: this.keyword,
        parentType: 1,
      }).then((res) => {
        // console.log(res);
        if (res.status == "200") {
          this.searchData = res.data.data;
          // console.log(this.searchData);
          if (this.searchData.length == 0) {
            this.noResultShow = true;
            this.searchShow = false;
            this.histroyContentShow = false;
          } else {
            this.noResultShow = false;
            this.searchShow = true;
          }
        }
        Indicator.close(); //加载完毕
      });
    },
    //点击历史搜索，跳转搜索结果页
    goSearchDetail(title) {
      this.keyword = title;
      this.onSearch();
    },
    //返回上一页打开相对应的景点
    backPrev(id) {
      this.pid = id;
      this.$router.push("/scenicDetail/" + this.pid + "/" + this.languageType);
    },
    // 点击进度条跳到指定点播放
    // PS：此处不要用click，否则下面的拖动进度点事件有可能在此处触发，此时e.offsetX的值非常小，会导致进度条弹回开始处
    progressBarBg(e) {
      let audio = document.getElementById("audioPlayer");
      if (!audio.paused || audio.currentTime != 0) {
        // let pgsWidth = $(".progress-bar-bg").width();
        let pgsWidth =
          document.getElementsByClassName("progress-bar-bg")[0].offsetWidth;
        let rate = e.offsetX / pgsWidth;
        audio.currentTime = audio.duration * rate;
        this.updateProgress(audio);
      }
    },
    // 鼠标拖动进度点时可以调节进度
    // 只有音乐开始播放后才可以调节，已经播放过但暂停了的也可以
    // 鼠标按下时
    progressDot(e) {
      let that = this;
      let audio = document.getElementById("audioPlayer");
      let dot = document.getElementById("progressDot");
      if (!audio.paused || audio.currentTime != 0) {
        let oriLeft = dot.offsetLeft;
        let mouseX = e.clientX;
        let maxLeft = oriLeft; // 向左最大可拖动距离
        let maxRight =
          document.getElementById("progressBarBg").offsetWidth - oriLeft; // 向右最大可拖动距离

        // 禁止默认的选中事件（避免鼠标拖拽进度点的时候选中文字）
        if (e.preventDefault) {
          e.preventDefault();
        } else {
          e.returnValue = false;
        }

        // 禁止事件冒泡
        if (e && e.stopPropagation) {
          e.stopPropagation();
        } else {
          window.event.cancelBubble = true;
        }

        // 开始拖动
        document.onmousemove = function (e) {
          let length = e.clientX - mouseX;
          if (length > maxRight) {
            length = maxRight;
          } else if (length < -maxLeft) {
            length = -maxLeft;
          }
          let pgsWidth =
            document.getElementsByClassName("progress-bar-bg")[0].width;
          let rate = (oriLeft + length) / pgsWidth;
          audio.currentTime = audio.duration * rate;
          that.updateProgress(audio);
        };

        // 拖动结束
        document.onmouseup = function () {
          document.onmousemove = null;
          document.onmouseup = null;
        };
      }
    },
    // 音频播放停止事件
    audioPlay() {
      let that = this;
      var audio = document.getElementById("audioPlayer");
      // 监听音频播放时间并更新进度条
      audio.addEventListener(
        "timeupdate",
        function () {
          that.updateProgress(audio);
        },
        false
      );

      // 监听播放完成事件
      audio.addEventListener(
        "ended",
        function () {
          that.audioEnded();
        },
        false
      );
      // 改变播放/暂停图片
      if (audio.paused) {
        let audio = document.getElementById("audioPlayer");
        // 开始播放当前点击的音频
        audio.play();
        that.playing = true;
      } else {
        audio.pause();
        that.playing = false;
      }
    },
    // 切换marker音频停止事件
    changeMarkAudioPlay() {
      let audio = document.getElementById("audioPlayer");
      // 改变播放/暂停图片
      if (audio.paused) {
        this.audioEnded();
        this.audioInit();
      } else {
        audio.pause();
        this.audioEnded();
        this.audioInit();
      }
    },
    // 更新进度条与当前播放时间 audio - audio对象
    updateProgress(audio) {
      let value = audio.currentTime / audio.duration;
      this.barWidth = value * 100;
      this.dotLeft = value * 100;
      this.audioCurTime = this.transTime(audio.currentTime);
      this.duration = this.transTime(audio.duration);
    },
    /**
     * 音频播放时间换算
     * @param {number} value - 音频当前播放时间，单位秒
     */
    transTime(value) {
      let time = "";
      let h = parseInt(value / 3600);
      value %= 3600;
      let m = parseInt(value / 60);
      let s = parseInt(value % 60);
      if (h > 0) {
        time = this.formatTime(h + ":" + m + ":" + s);
      } else {
        time = this.formatTime(m + ":" + s);
      }

      return time;
    },
    /**
     * 格式化时间显示，补零对齐
     * eg：2:4  -->  02:04
     * @param {string} value - 形如 h:m:s 的字符串
     */
    formatTime(value) {
      let time = "";
      let s = value.split(":");
      let i = 0;
      for (; i < s.length - 1; i++) {
        time += s[i].length == 1 ? "0" + s[i] : s[i];
        time += ":";
      }
      time += s[i].length == 1 ? "0" + s[i] : s[i];

      return time;
    },
    //  播放完成时把进度调回开始的位置
    audioEnded() {
      this.barWidth = 0;
      this.dotLeft = 0;
      this.playing = false;
      this.audioCurTime = "00:00";
    },
    audioInit() {
      let that = this;
      let audio = document.getElementById("audioPlayer");
      if (this.audioSrc != "") {
        audio.load();
        audio.addEventListener("canplay", function () {
          //监听audio是否加载完毕，如果加载完毕，则读取audio播放时间
          that.duration = that.transTime(audio.duration);
        });
      }
    },
    //全局音频的播放暂停
    playAudioFn(index) {
      let that = this;
      this.audioIndex = index;
      let audio = this.$refs.audioPlay;
      if (this.curAudio != this.audioIndex) {
        // audio.pause();
        // this.playing = false;
        this.audioAll = this.detailData.webPoiVos[index].audio;
        this.curAudio = this.audioIndex;
        setTimeout(() => {
          audio.play();
        }, 1);
        this.playing = true;
        // 监听播放完成事件
        audio.addEventListener(
          "ended",
          function () {
            that.audioEnded();
          },
          false
        );
      } else {
        // 改变播放/暂停图片
        if (audio.paused) {
          audio.play();
          this.playing = true;
        } else {
          audio.pause();
          this.playing = false;
        }
      }
    },
    //首页数据
    getInitData() {
      Indicator.open({
        text: "加载中...",
        spinnerType: "fading-circle",
      });
      indexApi({
        languageType: this.languageType,
      }).then((res) => {
        // console.log(res);
        if (res.status == "200") {
          this.initData = res.data.data;
          console.log(this.initData);
          this.geoMarkerIcon = this.initData.webPoiTypeVos;
          this.scenicGress = this.initData.webPoiTypeVos.find(
            (item) => item.id == 40
          ); //初始化景区级别数据
          this.mapPostionData = this.initData.webScenicVos.find(
            (item) => item.parentType == 1
          );
          // console.log(this.initData);
          // console.log(this.mapPostionData);
          this.initMap(); //初始化地图
        }
        Indicator.close();
      });
    },
    //首页底部详情数据
    getIndexDetailData() {
      indexDetailApi({
        languageType: this.languageType,
        poiTypeId: this.poiTypeId,
        poiTagId: this.poiTagId,
        region: this.region,
      }).then((res) => {
        if (res.status == "200") {
          this.detailData = res.data.data;
          this.detailList = this.detailData.webPoiVos;
          console.log(this.detailData);
          //定义marker点集合
          this.initMap(); //初始化地图
        }
      });
    },
    //首页底部详情数据
    getIndexDetailDatas() {
      indexDetailApi({
        languageType: this.languageType,
        poiTypeId: 40,
        poiTagId: this.poiTagId,
        region: this.region,
      }).then((res) => {
        if (res.status == "200") {
          this.detailData = res.data.data;
          for (var i in this.detailData.webPoiVos) {
            if (this.detailData.webPoiVos[i].tagName == "2A") {
              this.detailData.webPoiVos.splice(i, 1);
              // this.detailList.push();
              // this.detailList = this.detailData.webPoiVos;
            }
          }
          for (var j in this.detailData.webPoiVos) {
            if (this.detailData.webPoiVos[j].tagName == "2A") {
              this.detailData.webPoiVos.splice(j, 1);
              // this.detailList.push();
            }
          }
          for (var k in this.detailData.webPoiVos) {
            if (this.detailData.webPoiVos[k].tagName == "2A") {
              this.detailData.webPoiVos.splice(k, 1);
              // this.detailList.push();
              // this.detailList = this.detailData.webPoiVos;
            }
          }
          this.detailList = this.detailData.webPoiVos;
          //定义marker点集合
          this.initMap(); //初始化地图
        }
      });
    },
    // 专题数据
    getTitleData() {
      indexTitleApi({
        languageType: this.languageType,
      }).then((res) => {
        // console.log(res);
        if (res.status == "200") {
          this.titleData = res.data.data;
        }
      });
    },
    //景区路线
    getAllLineData() {
      allLineApi({
        languageType: this.languageType,
        parentType: 1,
      }).then((res) => {
        // console.log(res);
        if (res.status == "200") {
          this.allLineData = res.data.data;
          this.newlines = JSON.parse(JSON.stringify(this.allLineData));
          for (var j in this.allLineData) {
            this.newlines[j].linePointVos = [];
            for (var i in this.allLineData[j].linePointVos) {
              if (
                typeof this.allLineData[j].linePointVos[i].pointId !=
                "undefined"
              ) {
                this.newlines[j].linePointVos.push(
                  this.allLineData[j].linePointVos[i]
                );
              }
            }
          }
          // console.log(this.newlines);
        }
      });
    },
    //跳转到导航
    toNavigationFn(item) {
      var ua = navigator.userAgent.toLowerCase();
      if (ua.match(/MicroMessenger/i) == "micromessenger") {
        //在微信就用微信地图
        this.commonFun.openLocation({
          name: item.name, // 位置名
          latitude: item.latitude, // 纬度，浮点数，范围为90 ~ -90
          longitude: item.longitude, // 经度，浮点数，范围为180 ~ -180。
          address: item.address, // 地址详情说明
          scale: 12, // 地图缩放级别,整形值,范围从1~28。默认为最大
          infoUrl: "", // 在查看位置界面底部显示的超链接,可点击跳转
        });
      }
    },
    //语言
    getLanguageData() {
      // Indicator.open();
      languageDataApi().then((res) => {
        // console.log(res);
        if (res.status == "200") {
          this.languageData = res.data.data;
          // console.log(this.languageData);
        }
      });
      // Indicator.close();
    },
  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {
    this.lanData = this.manyLanData[this.languageType - 1];
    this.searchList = JSON.parse(localStorage.getItem("keyword")) || []; //先读取local里存储的历史记录
    this.getInitData(); //调用请求数据的方法
    this.getTitleData(); //调用专题数据
    this.getIndexDetailDatas(); //初始化详情数据
    this.getLanguageData(); //语言选择
    this.getAllLineData(); //全域景区路线
    // this.audioInit();
  },
};
</script>
<style lang="less">
.index {
  display: flex;
  width: 100%;
  height: 100vh;
  position: relative;

  //地图
  #container {
    width: 100%;
    height: 100%;
    position: relative;

    // 去除地图logo
    .amap-logo {
      display: none !important;
    }

    .amap-copyright {
      opacity: 0;
    }
    .curMarker {
      width: 48px;
      height: 54px;
      background: url("./image/location.svg") no-repeat;
      background-size: 100%;
    }
    //marker的样式
    .contain {
      width: 4rem;
      height: 4.5rem;
      background: url("./image/spot_route.svg") no-repeat;
      background-size: 100%;
      border-radius: 50%;
      color: #000;
      text-align: center;
      line-height: 4rem;
      font-size: 1.6rem;
    }

    .contain-marker1 {
      width: 4rem;
      height: 2.5rem;
      background: url("./image/2A.png") no-repeat;
      background-size: 100%;
      color: #fff;
      text-align: center;
      line-height: 3rem;
      font-size: 1rem;
      font-weight: 400;
    }

    .contain-marker2 {
      width: 4rem;
      height: 2.5rem;
      background: url("./image/5A.png") no-repeat;
      background-size: 100%;
      color: #fff;
      text-align: center;
      line-height: 3rem;
      font-size: 1rem;
      font-weight: 400;
    }

    .contain-active {
      width: 4rem;
      height: 4.5rem;
      background: url("./image/spot_route(1).svg") no-repeat;
      background-size: 100%;
      border-radius: 50%;
      color: #fff;
      text-align: center;
      line-height: 4rem;
      font-size: 1.6rem;
    }

    //marker的文本内容
    .amap-marker-label {
      position: absolute;
      z-index: 2;
      // border: 1px solid white;
      // border-radius: 2.1rem;
      // background-color: white;
      // opacity: 0.96;
      border: 0;
      background: none;
      white-space: nowrap;
      font-weight: 600;
      cursor: default;
      padding: 3px 7px;
      font-size: 1.1rem;
      text-shadow: -1px 1px 0 #fff, 0.5px 0.5px 0 #fff;
    }
  }

  .navContent::-webkit-scrollbar {
    display: none;
  }

  //左侧手绘开关
  .leftSwitch1 {
    width: 4.8rem;
    height: 4.8rem;
    background: url("./image/button_classification.svg") no-repeat;
    background-size: 100%;
    position: absolute;
    top: 1.5rem;
    left: 1.5rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .switch {
      width: 3rem;
      height: 1.2rem;
      display: flex;
      justify-content: center;
      align-items: center;

      .mint-switch {
        width: 100%;
        height: 100%;
        margin: 0;

        .mint-switch-core {
          width: 100%;
          height: 100%;
        }

        .mint-switch-input:checked + .mint-switch-core {
          background: #5a6570;
        }

        .mint-switch-core::before {
          width: 100%;
          height: 100%;
        }

        .mint-switch-core::after {
          width: 35%;
          height: 100%;
        }
      }
    }

    .leftTitel {
      width: 100%;
      height: 1.8rem;
      overflow: hidden;
      text-align: center;
      font-size: 0.9rem;
      font-weight: 400;
      text-align: CENTER;
      color: #5a6570;
      line-height: 1.8rem;
      padding: 0 0.4rem;
    }
  }

  .leftSwitch2 {
    width: 4.8rem;
    height: 4.8rem;
    background: url("./image/button_classification.svg") no-repeat;
    background-size: 100%;
    position: absolute;
    top: 7.5rem;
    left: 1.5rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .rightIcon {
      width: 1.8rem;
      height: 1.8rem;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .leftTitel {
      width: 100%;
      height: 1.8rem;
      overflow: hidden;
      text-align: center;
      font-size: 0.9rem;
      font-weight: 400;
      text-align: CENTER;
      color: #5a6570;
      line-height: 1.8rem;
      padding: 0 0.4rem;
    }
  }

  //右侧选择
  .rightSelect1 {
    width: 4.8rem;
    position: absolute;
    top: 1.5rem;
    right: 1.5rem;

    .rightContent {
      width: 4.8rem;
      height: 4.8rem;
      background: url("./image/button_classification.svg") no-repeat;
      background-size: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-bottom: 0.6rem;

      .rightIcon {
        width: 1.8rem;
        height: 1.8rem;

        img {
          width: 100%;
          height: 100%;
        }
      }

      .rightTitel {
        width: 100%;
        height: 1.4rem;
        overflow: hidden;
        text-align: center;
        padding: 0 0.4rem;
        line-height: 1.4rem;
      }
    }
  }

  // 右侧语言选择
  .rightSelected {
    // width: 4.8rem;
    height: 3.8rem;
    position: absolute;
    right: 0;
    top: 44rem;
    background: #fff;
    border-radius: 4rem 0 0 4rem;
    overflow: hidden;
    display: flex;
    align-items: center;

    .allShow {
      display: flex;
      align-items: center;

      .selectLanguage {
        width: 3.2rem;
        height: 3.2rem;
        background: #5c7190;
        border-radius: 50%;
        margin: 0 0.5rem 0 0.5rem;
        display: flex;
        justify-content: center;
        align-items: center;

        .selectIcon {
          width: 2.4rem;
          height: 2.44rem;
          border-radius: 50%;
          background: url("./image/globel.svg") no-repeat 100%;
          background-size: 100%;
        }
      }
    }

    .languageSelect {
      width: 10.3rem;
      height: 3.8rem;
      background: #fff;
      margin: 0 0.9rem 0 0;
      display: flex;
      justify-content: center;
      align-items: center;

      .selectContent {
        width: 25%;
        height: 1.7rem;
        text-align: center;
        line-height: 1.7rem;
        border-right: 0.1rem solid #eee;
        font-size: 1.2rem;
        font-weight: 500;
        font-family: PingFang SC, PingFang SC-Regular;
      }

      .selectContent-active {
        width: 25%;
        height: 1.7rem;
        text-align: center;
        color: #5c7190;
        line-height: 1.7rem;
        border-right: 0.1rem solid #eee;
        font-size: 1.2rem;
        font-weight: 500;
        font-family: PingFang SC, PingFang SC-Regular;
      }
    }
  }

  //marker的详情页
  .scenicDetail {
    width: 100%;
    height: 100vh;
    position: absolute;
    z-index: 666;
    background-color: rgba(0, 0, 0, 0.2);

    // .backBtn {
    //   width: 3.4rem;
    //   height: 3.4rem;
    //   position: absolute;
    //   top: 1.5rem;
    //   left: 1.5rem;
    //   background: url("./image/button_back.svg") no-repeat;
    //   background-size: 100%;
    // }
    //显示内容
    .showContent {
      width: 90%;
      height: 86vh;
      background: #fafafa;
      border-radius: 0.8rem;
      padding: 1.6rem;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      position: absolute;
      left: 50%;
      margin-left: -45%;
      top: 50%;
      margin-top: -43vh;
      z-index: 102;

      .upIcon,
      .downIcon {
        width: 3.8rem;
        height: 2rem;
        margin: 0 auto 1.4rem;
        background: url("./image/up1.svg") no-repeat;
        background-size: 100%;
        flex-shrink: 0;
      }

      .downIcon {
        background: url("./image/down1.svg") no-repeat;
        background-size: 100%;
      }

      //头部内容
      .showTop {
        width: 100%;
        height: 12%;
        position: relative;
        flex-shrink: 0;

        .topTitle {
          width: 100%;
          height: 3.8rem;
          display: flex;
          font-size: 2rem;
          align-items: flex-end;

          .titleName {
            width: 70%;
            height: 3.8rem;
            line-height: 2.8rem;
            font-size: 3.2rem;
            font-weight: 400;
            text-align: LEFT;
            color: #504847;
            line-height: 3.8rem;
            letter-spacing: -0.01rem;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 1;
            overflow: hidden;
          }

          .titleBtn {
            width: 5rem;
            height: 2.2rem;
            border-radius: 0.4rem;
            text-align: center;
            line-height: 2.2rem;
            margin-left: 1rem;
            background: #bd7c68;
            color: #fff;
            font-size: 1.3rem;
            font-weight: 400;
            margin-bottom: 0.3rem;
          }
        }

        .topAddress {
          height: 2rem;
          line-height: 2rem;
          margin: 1.2rem 0;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 1;
          overflow: hidden;

          .icon-adrress {
            width: 1.8rem;
            height: 1.8rem;
            margin-right: 0.4rem;
            background: url("./image/icon_address.svg") no-repeat;
            background-size: 100%;
            float: left;
          }

          .ps {
            height: 2;
            line-height: 2rem;
            font-size: 1.4rem;
            color: #5a6570;
          }
        }

        .closeBtn {
          width: 2rem;
          height: 2rem;
          position: absolute;
          right: 0;
          top: 0;
          background: url("./image/icon_close (1).svg") no-repeat;
          background-size: 100%;
        }
      }

      .detailCover {
        width: 100%;
        height: 26%;
        overflow: hidden;
        flex-shrink: 0;

        .swiper {
          width: 100%;
          height: 100%;

          .mint-swipe-item {
            background: #fff;
          }

          .mint-swipe-indicators {
            position: absolute;
            bottom: 0.5rem;
            left: 50%;
            transform: translateX(-50%);
          }
        }
      }

      //语音
      .intro {
        width: 100%;
        height: 10%;
        border-radius: 0.8rem;
        background: #fff;
        overflow: hidden;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-shrink: 0;
        position: relative;

        .audio {
          width: 100%;
          height: 100%;
          background: #fff;
          display: flex;
          align-items: center;
          box-sizing: border-box;

          .icon_play {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 3rem;
            height: 3rem;
            border-radius: 50%;
            background: #f85f5f;
          }

          .audio-box {
            width: 28rem;
            height: 4.8rem;

            .player-box {
              width: 100%;
              height: 4.8rem;
              justify-content: space-between;
              padding: 0 2.8rem 0 0.8rem;
              // margin: 0.4rem auto 0;
              display: flex;
              align-items: center;
              //   justify-content: center;
              background-color: #fff;

              .audio-left {
                /* float: left; */
                margin-right: 0.4rem;
                width: 3.2rem;
                height: 3.2rem;
                background: #615856;
                border-radius: 50%;
                display: flex;
                justify-content: center;
                align-items: center;

                a {
                  width: 1.6rem;
                  height: 1.6rem;

                  img {
                    width: 100%;
                    height: 100%;
                  }
                }
              }

              #audioPlayer {
                cursor: pointer;
              }

              .audio-right {
                flex: 1;
                width: 0;
                height: 100%;
                justify-content: center;
                padding-left: 0.5rem;

                .progress-bar-bg {
                  width: 100%;
                  background: #eee;
                  position: relative;
                  height: 0.2rem;
                  cursor: pointer;
                  border-radius: 0.2rem;
                  margin-bottom: 0.6rem;
                  margin-top: 2rem;

                  span {
                    content: " ";
                    width: 1rem;
                    height: 1rem;
                    border-radius: 50%;
                    background-color: #fff;
                    border: 0.1rem solid #999999;
                    position: absolute;
                    left: 0;
                    top: 50%;
                    margin-top: -0.5rem;
                    margin-left: -0.5rem;
                    cursor: pointer;
                    z-index: 2;
                  }

                  .progress-bar {
                    background: #5a6570;
                    width: 0;
                    height: 0.2rem;
                    // margin-top: -0.01rem;
                    border-radius: 0.2rem;
                    position: absolute;
                    top: 50%;
                    left: 0;
                    transform: translateY(-50%);
                    z-index: 1;
                  }
                }

                .audio-time {
                  width: 100%;
                  height: 1.7rem;
                  overflow: hidden;
                  color: #999999;
                  justify-content: flex-end;
                  // margin-top: 0.16rem;
                  line-height: 1.7rem;
                  font-size: 1.2rem;
                  display: flex;
                  align-items: center;
                }
              }
            }
          }
        }

        .audioImg {
          width: 8.3rem;
          height: 6rem;
          position: absolute;
          top: 0;
          right: 0;
          background: url("./image/house21.svg") no-repeat;
          background-size: 100%;
          z-index: 0;
        }
      }

      .detailAll {
        width: 100%;
        height: 40%;
        flex-shrink: 0;

        //中间介绍
        .centerIntroduce {
          width: 100%;
          height: 100%;
          padding-bottom: 1rem;
          font-size: 1.4rem;
          font-family: PingFang SC, PingFang SC-Regular;
          font-weight: 400;
          color: #302d2a;
          overflow: auto;
        }
      }

      //底部导航
      .introduceBtn {
        width: 100%;
        height: 7%;
        display: flex;
        justify-content: space-between;

        .lanBtn {
          width: 13.8rem;
          height: 4.2rem;
          line-height: 4.2rem;
          text-align: center;
          color: #fff;
          font-size: 1.3rem;
          background: url("./image/Group 555.svg") no-repeat;
          background-size: 100%;
          margin-right: 0.5rem;
          display: flex;
          justify-content: center;
          align-items: center;

          .icon-daolan {
            width: 1.6rem;
            height: 1.6rem;
            background: url("./image/icon_daolan.svg") no-repeat;
            background-size: 100%;
          }
        }

        .hangBtn {
          width: 13.8rem;
          height: 4.2rem;
          line-height: 4.2rem;
          text-align: center;
          color: #fff;
          font-size: 1.3rem;
          overflow: hidden;
          background: url("./image/Group 556.svg") no-repeat;
          background-size: 100%;
          margin: 0 auto;
          display: flex;
          justify-content: center;
          align-items: center;

          .icon-daohang {
            width: 1.6rem;
            height: 1.6rem;
            background: url("./image/icon_navigation.svg") no-repeat;
            background-size: 100%;
          }
        }
      }
    }
  }

  //专题页面
  .wrapper {
    width: 100%;
    height: 100vh;
    background: #faf8f7;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 900;
    display: flex;
    justify-content: center;
    align-items: center;

    .top {
      width: 16.8rem;
      height: 3.9rem;
      position: absolute;
      top: 2.8rem;
      left: 1.5rem;
      margin-bottom: 2.4rem;
      display: flex;
      justify-content: space-between;

      .leftIcon {
        width: 3.4rem;
        height: 3.4rem;
        background: url("./image/button_back121.svg") no-repeat;
        background-size: 100%;
      }

      .rightTitle {
        width: 12.7rem;
        height: 3.8rem;
        background: url("./image/zuanti.svg") no-repeat;
        background-size: 100%;
      }
    }

    .topRightImg {
      width: 12rem;
      height: 9.4rem;
      position: absolute;
      top: 0;
      right: 0;
      background: url("./image/chuan.svg") no-repeat;
      background-size: 100%;
      z-index: -1;
    }

    .rightBottomImg {
      width: 27.8rem;
      height: 15.2rem;
      position: absolute;
      bottom: 0rem;
      left: 0;
      z-index: -1;
      background: url("./image/house.svg") no-repeat;
      background-size: 100%;
    }

    .listAll {
      width: 30rem;
      height: 45rem;
      position: relative;
      overflow: hidden;
      border: 0.2rem solid #666;
      border-radius: 00.8rem;

      .mint-swipe-item {
        width: 30rem;
        height: 45rem;

        img {
          width: 100%;
          height: 100%;
        }
      }
    }

    .closeWrapper {
      width: 2.4rem;
      height: 2.4rem;
      border-radius: 50%;
      // background: url("./image/icon_window_close.svg") no-repeat;
      // background-size: 100%;
      background: #000;
      opacity: 0.2;
      position: fixed;
      bottom: 3rem;
      left: 50%;
      margin-left: -1.2rem;

      img {
        width: 100%;
        height: 100%;
      }
    }
  }

  .buttomNav {
    width: 100%;
    position: fixed;
    bottom: 0;
    background: #fff;
    border-radius: 2rem 2rem 0rem 0rem;
    z-index: 20;
    .iconTop1,
    .iconTop2 {
      width: 3.8rem;
      height: 2rem;
      background: url("./image/up.svg") no-repeat;
      background-size: 100%;
      margin: 0.5rem auto;
    }

    .iconTop2 {
      background: url("./image/down.svg") no-repeat;
    }

    .navContent {
      width: 100%;
      height: 5rem;
      border-bottom: 0.1rem solid #eee;
      overflow-x: scroll;
      overflow-y: hidden;
      white-space: nowrap;

      .navSelect {
        display: inline-block;
        margin: 0 1.6rem;
        line-height: 5rem;
        position: relative;

        .selAll {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          line-height: 5rem;

          .icon-select {
            width: 2.4rem;
            height: 2.4rem;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-right: 0.2rem;

            img {
              width: 2.4rem;
              height: 2.4rem;
            }
          }

          .icon-select-active {
            width: 2.4rem;
            height: 2.4rem;
            border-radius: 50%;
            background: #5a6570;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-right: 0.2rem;

            img {
              width: 2.4rem;
              height: 2.4rem;
            }
          }

          .navName {
            height: 1.7rem;
            font-size: 1.5rem;
            font-family: PingFang SC, PingFang SC-Regular;
            font-weight: 600;
            text-align: left;
            color: #949ba3;
            line-height: 1.7rem;
            letter-spacing: 0rem;
            overflow: hidden;
          }

          //点击后改变的样式
          .Tabactive {
            font-weight: 600;
            color: #5a6570;
          }
        }
      }
    }

    //子导航
    .navDetail {
      width: 100%;
      height: 35rem;
      display: flex;
      flex-direction: column;
      align-items: center;

      .topSonNav::-webkit-scrollbar {
        display: none;
      }

      .topSonNav {
        width: 100%;
        height: 4rem !important;
        border-bottom: 1px solid #eee;
        overflow-x: scroll;
        overflow-y: hidden;
        white-space: nowrap;
        flex-shrink: 0;

        .topSonTab {
          height: 4rem;
          text-align: center;
          line-height: 4rem;
          position: relative;
          display: inline-block;
          margin: 0 1rem;
        }

        .lineTab {
          width: 2.3rem;
          height: 0.2rem;
          background: #5a6570;
          position: absolute;
          left: 50%;
          margin-left: -1.15rem;
          top: 3.2rem;
        }
      }

      .sonNav {
        width: 100%;
        height: 100%;
        display: flex;

        .leftAll {
          width: 8rem;
          height: 100%;
          border-right: 1px solid #eee;

          .leftSonNav {
            width: 8rem;
            height: 100%;
            overflow: auto;

            .leftSonTab {
              width: 100%;
              height: 3.6rem;
              text-align: center;
              line-height: 3.6rem;
              font-size: 1.4rem;
              font-weight: 500;
              color: #b8b8b8;
            }

            .leftActive {
              background: #e7e9ec;
              color: #5a6570;
            }
          }
        }

        .detailContent {
          width: 100%;
          height: 100%;
          overflow: auto;
          padding-bottom: 5rem;

          .detailShow {
            width: 100%;
            height: 8rem;
            box-sizing: border-box;
            padding: 0.4rem 0.8rem;
            // border: 1px solid #666;
            display: flex;

            .detailImg {
              width: 12rem;
              height: 7rem;
              display: flex;
              justify-content: center;
              align-items: center;
              position: relative;
              overflow: hidden;

              img {
                width: 100%;
                height: 100%;
              }

              .ipa {
                width: 2.4rem;
                height: 2.4rem;
                border-radius: 50%;
                position: absolute;

                .icon-push,
                .icon-push-active {
                  width: 2.4rem;
                  height: 2.4rem;
                  border-radius: 50%;
                  background: url("./image/icon_play.svg") no-repeat;
                  background-size: 100%;
                }

                .icon-push-active {
                  background: url("./image/icon_suspend.svg") no-repeat;
                  background-size: 100%;
                }
              }
            }

            .detailText {
              width: 100%;
              height: 100%;
              margin-left: 0.8rem;
              display: flex;
              flex-direction: column;
              justify-content: space-between;
              position: relative;

              .toDetail {
                width: 1.6rem;
                height: 1.6rem;
                position: absolute;
                top: 0.4rem;
                right: 1.6rem;
                background: url("./image/icon_back.svg") no-repeat;
                background-size: 100%;
              }

              .textTitle {
                width: 100%;
                height: 2rem;
                line-height: 2rem;
                font-size: 1.4rem;
                font-weight: 500;
                color: #444444;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 1;
                overflow: hidden;
              }

              .textAddress {
                width: 100%;
                height: 1.5rem;
                line-height: 1.5rem;

                .icon_address {
                  width: 1.4rem;
                  height: 1.4rem;
                  background: url("./image/icon_address.svg") no-repeat;
                  background-size: 100%;
                  float: left;
                }

                span {
                  display: -webkit-box;
                  -webkit-box-orient: vertical;
                  -webkit-line-clamp: 1;
                  overflow: hidden;
                  color: #666666;
                  font-size: 1.2rem;
                  font-family: PingFang SC, PingFang SC-Light;
                  font-weight: 300;
                }
              }

              .textFunction {
                width: 100%;
                height: 2rem;
                display: flex;
                justify-content: space-evenly;
                align-items: center;

                .choice1,
                .choice2 {
                  width: 4.4rem;
                  height: 1.8rem;
                  overflow: hidden;
                  padding: 0 0.1rem;
                  color: #fff;
                  float: left;
                  text-align: center;
                  line-height: 1.8rem;
                  background: url("./image/Union4.svg") no-repeat;
                  background-size: 100%;
                }

                .choice2 {
                  background: url("./image/Union3.svg") no-repeat;
                  background-size: 100%;
                }
              }
            }
          }
        }
      }
    }
  }

  //底部导航栏
  .bottomNavCon {
    width: 100%;
    height: 8rem;
    background: #fafafa;
    position: absolute;
    bottom: 0;
    left: 0;
    overflow: hidden;
    z-index: 2;

    img {
      width: 100%;
      height: 100%;
      opacity: 0.9;
      position: absolute;
      z-index: 0;
    }

    .navAll {
      width: 100%;
      height: 100%;
      position: absolute;
      z-index: 1;
      display: flex;
      align-items: center;

      .indexBtn,
      .specialBtn,
      .lineBtn,
      .roundBtn {
        width: 25%;
        height: 6rem;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;

        .leftIcon {
          width: 3.6rem;
          height: 3.6rem;
          overflow: hidden;
          display: flex;
          justify-content: center;
          align-items: flex-end;

          img {
            width: 3rem;
            height: 3rem;
          }
        }

        .rightText {
          height: 1.7rem;
          font-size: 1.2rem;
          font-family: PingFang SC, PingFang SC-Regular;
          font-weight: 400;
          text-align: CENTER;
          color: #615856;
          line-height: 1.7rem;
          letter-spacing: 0rem;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 1;
          overflow: hidden;
        }
      }
    }
  }

  // 路线
  .path {
    width: 100%;
    height: 100vh;
    background: rgba(0, 0, 0, 0.5);
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    z-index: 30;
    .pathContent {
      width: 100%;
      height: 100%;
      background: #f8f8f8;

      .photoTitle {
        width: 100%;
        height: 8.2rem;
        background: url("./image/Group 589.svg") no-repeat;
        background-size: 100%;
      }

      .lineContent {
        width: 100%;
        height: 50rem;
        overflow: auto;
        padding: 1.6rem 1.4rem 0;
        margin-bottom: 8rem;

        .contentTitle {
          font-size: 1.8rem;
          font-family: PingFang SC, PingFang SC-Semibold;
          font-weight: 600;
          text-align: LEFT;
          color: #454e55;
          padding: 0 0.2rem;
          letter-spacing: 0rem;

          .tox {
            width: 2.4rem;
            height: 2.4rem;
            background: url("./image/tox.svg") no-repeat;
            background-size: 100%;
            float: right;
          }
        }

        .contentLine {
          width: 100%;
          float: right;
          padding: 1.2rem;
          margin: 1.2rem 0 1.6rem;
          background: #ffffff;
          border-radius: 0.8rem;

          .lineTitle {
            font-size: 1.2rem;
            font-family: PingFang SC, PingFang SC-Regular;
            font-weight: 400;
            text-align: LEFT;
            color: #666666;
            margin-bottom: 1.5rem;
          }

          .line {
            width: 100%;

            .detailLine {
              // width: 13rem;
              // height: 2.5rem;
              display: inline-block;
              position: relative;

              .linePoint {
                font-size: 1.4rem;
                font-family: PingFang SC, PingFang SC-Medium;
                font-weight: 500;
                text-align: LEFT;
                color: #333333;
                display: flex;
                justify-content: center;
                align-items: center;

                .to {
                  width: 2rem;
                  height: 2rem;
                  display: inline-block;
                  background: url("./image/to.svg") no-repeat;
                  background-size: 100%;
                  margin: 0 0.5rem;
                }
              }
            }
          }
        }
      }
    }

    .closeWrapper {
      width: 2.4rem;
      height: 2.4rem;
      border-radius: 50%;
      // background: url("./image/icon_window_close.svg") no-repeat;
      // background-size: 100%;
      background: #000;
      opacity: 0.2;
      position: fixed;
      bottom: 3rem;
      left: 50%;
      margin-left: -1.2rem;

      img {
        width: 100%;
        height: 100%;
      }
    }
  }

  //路线导航
  .lineNavigation {
    width: 100%;
    height: 24.2rem;
    position: absolute;
    left: 0;
    bottom: 0;
    background: #f5f5f5;
    padding: 1.5rem 0 0 0.6rem;
    z-index: 666;

    .navigationTab {
      width: 34rem;
      height: 3rem;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1;
      overflow: hidden;

      .closeNavigation {
        width: 2.4rem;
        height: 2.4rem;
        border-radius: 50%;
        background: url("./image/icon_window_close(1).svg") no-repeat;
        background-size: 100%;
        position: absolute;
        top: 1.2rem;
        right: 0.7rem;
      }

      .tabName {
        height: 2.5rem;
        line-height: 2.5rem;
        display: inline-block;
        font-size: 1.4rem;
        padding: 0 1rem;
        font-family: PingFang SC, PingFang SC-Medium;
        font-weight: 500;
        color: #999999;
      }

      .tabNameActive {
        color: #454e55;
        border-bottom: 0.15rem solid #454e55;
      }
    }

    // 详情展示内容
    .navigationWindow {
      width: 100%;
      height: 10.8rem;
      padding: 1.5rem 0.6rem;

      .windowImg {
        width: 7.8rem;
        height: 7.8rem;
        display: flex;
        border-radius: 0.4rem;
        justify-content: center;
        align-items: center;
        float: left;
        overflow: hidden;

        img {
          width: 100%;
          height: 100%;
        }

        .icon_audio,
        .icon_audio-active {
          width: 2.4rem;
          height: 2.4rem;
          border-radius: 50%;
          background: url("./image/icon_play.svg") no-repeat;
          background-size: 100%;
          position: absolute;
          z-index: 4;
        }

        .icon_audio-active {
          background: url("./image/icon_suspend.svg") no-repeat;
          background-size: 100%;
        }
      }

      .windowContent {
        width: 20rem;
        height: 100%;
        display: inline-block;
        padding-left: 0.8rem;
        display: flex;
        flex-direction: column;
        justify-content: space-around;

        .windowTitle {
          height: 2rem;
          line-height: 2rem;
          font-size: 1.8rem;
          font-family: PingFang SC, PingFang SC-Medium;
          font-weight: 500;
          text-align: LEFT;
          color: #333333;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 1;
          overflow: hidden;
        }

        .WindowBtn {
          width: 100%;
          height: 2.8rem;

          .navigationBtn1,
          .navigationBtn2 {
            width: 6.8rem;
            height: 2.8rem;
            line-height: 2.8rem;
            display: inline-block;
            font-size: 1.4rem;
            font-family: PingFang SC, PingFang SC-Regular;
            font-weight: 400;
            color: #fff;
            text-align: center;
            margin-right: 1.2rem;
            background: url("./image/Union1.svg") no-repeat;
            background-size: 100%;
          }

          .navigationBtn2 {
            background: url("./image/Union2.svg") no-repeat;
            background-size: 100%;
          }
        }
      }

      //点击选择路线
      .line-bottom {
        .line-pois {
          width: 100%;
          height: 10rem;
          overflow-x: scroll;
          overflow-y: hidden;
          white-space: nowrap;
          padding-top: 2rem;

          .line-poi-item {
            position: relative;
            width: 10.4rem;
            height: 100%;
            // margin-right: 50px;
            display: inline-block;

            .line-item-index {
              margin: 0 auto;
              width: 3.2rem;
              height: 3.7rem;
              font-size: 1.4rem;
              font-stretch: normal;
              font-weight: 700;
              line-height: 3rem;
              letter-spacing: 0px;
              color: #5a6570;
              text-align: center;
              background: url("./image/spot_route.svg") no-repeat;
              background-size: 100%;
            }

            .line-item-index-active {
              margin: 0 auto;
              width: 3.2rem;
              height: 3.7rem;
              font-size: 1.4rem;
              font-stretch: normal;
              font-weight: 700;
              line-height: 3rem;
              letter-spacing: 0px;
              color: #fff;
              text-align: center;
              background: url("./image/spot_route(1).svg") no-repeat;
              background-size: 100%;
            }

            .line-item-name {
              margin-top: 0.8rem;
              line-height: 1.7rem;
              font-size: 1.2rem;
              font-weight: 500;
              text-align: center;
              color: #999999;
              letter-spacing: 0rem;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 1;
              overflow: hidden;
            }

            .line-item-name-active {
              color: #5a6570;
            }
          }

          .line-poi-item:last-child {
            margin-right: 0;
          }

          .line-poi-item:first-child {
            margin-left: 0.8rem;
          }

          .line-poi-item:not(:last-child)::after {
            position: absolute;
            content: "";
            width: 6rem;
            height: 0.2rem;
            border-bottom: 0.2rem dashed #5a6570;
            right: -3.2rem;
            top: 1rem;
            z-index: -1;
          }

          .line-poi-item.on {
            .line-item-index {
              // background: url("./images/Group_on.svg") no-repeat;
              color: #fff;
            }

            .line-item-name {
              font-size: 1.2rem;
              font-family: PingFang SC, PingFang SC-Regular;
              font-weight: 400;
              text-align: CENTER;
              color: #999999;
            }
          }
        }
      }
    }
  }

  //搜索页面
  .search {
    width: 100%;
    height: 100vh;
    padding: 0.9rem 1.6rem 0;
    position: fixed;
    background: #fff;
    z-index: 999;

    //搜索框
    .search-box {
      width: 100%;
      height: 3rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 0.5rem;
      position: relative;

      input {
        width: 29.5rem;
        height: 4.4rem;
        background: #fafafa;
        border-radius: 4.4rem;
        padding-left: 3rem;
      }

      .serchbtn {
        width: 1.7rem;
        height: 1.7rem;
        background: url("./image/icon_search.svg") no-repeat;
        background-size: 100%;
        position: absolute;
        top: 0.7rem;
        left: 1rem;
      }

      .onCancle {
        width: 4rem;
        height: 3rem;
        overflow: hidden;
        font-size: 1.6rem;
        border-radius: 3rem;
        font-weight: 500;
        text-align: center;
        line-height: 3rem;
        color: #43525c;
      }
    }

    //搜索内容
    .searchContent {
      width: 100%;
      height: 100%;
      background: #fff;
      position: fixed;
      top: 7rem;

      .sContent {
        width: 85%;
        height: 5.6rem;
        border-bottom: 1px solid #eee;
        display: flex;
        flex-direction: column;
        justify-content: space-around;

        .scenicItem {
          width: 100%;
          height: 3rem;

          .leftItem {
            display: flex;
            align-items: center;

            .itemIcon {
              width: 2.2rem;
              height: 2.2rem;
              margin-right: 0.5rem;

              img {
                width: 100%;
                height: 100%;
              }
            }

            .itemName {
              height: 2.2rem;
              line-height: 2.2rem;
              font-size: 1.6rem;
              color: #454e55;
              font-family: PingFang SC, PingFang SC-Regular;
              font-weight: 400;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 1;
              overflow: hidden;
            }
          }
        }
      }
    }

    .noResult {
      width: 100%;
      height: 100%;
      line-height: 50%;
      height: 3rem;
      margin-left: 7rem;
      position: absolute;
      top: 15rem;
      z-index: 444;
    }

    //历史框
    .histroy {
      width: 100%;
      height: 3rem;
      margin: 3rem 0 1.3rem;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .leftName {
        font-size: 1.6rem;
        font-family: PingFang SC, PingFang SC-Bold;
        font-weight: 700;
        color: #444444;
      }

      .icon_del {
        width: 2.8rem;
        height: 2.8rem;
        background: url("./image/button_delete.svg") no-repeat;
        background-size: 100%;
      }
    }

    //历史记录内容
    .histroyContent {
      width: 100%;
      height: 5.6rem;

      .histroyList {
        height: 5.6rem;
        padding: 1.7rem 0;
        display: flex;
        align-items: center;

        .icon_scenic {
          width: 2.2rem;
          height: 2.2rem;
          background: #fff;
          margin-right: 0.5rem;
        }

        .areaName {
          height: 2.2rem;
          font-size: 1.6rem;
          font-family: PingFang SC, PingFang SC-Regular;
          font-weight: 400;
          text-align: LEFT;
          color: #454e55;
          line-height: 2.2rem;
          letter-spacing: 0rem;
        }
      }
    }
  }

  //语言组件
  .audio {
    width: 28rem;
    height: 4.8rem;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    margin-top: 0.4rem;

    .icon_play {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 3rem;
      height: 3rem;
      border-radius: 50%;
    }

    .audio-box {
      width: 28rem;
      height: 4.8rem;

      .player-box {
        width: 100%;
        height: 4.8rem;
        justify-content: space-between;
        padding: 0 2.8rem 0 0.8rem;
        // margin: 0.4rem auto 0;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #fff;

        .audio-left {
          /* float: left; */
          margin-right: 0.4rem;
          width: 3.2rem;
          height: 3.2rem;
          background: #5a6570;
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;

          a {
            width: 1.6rem;
            height: 1.6rem;

            img {
              width: 100%;
              height: 100%;
            }
          }
        }

        #audioPlayer {
          cursor: pointer;
        }

        .audio-right {
          flex: 1;
          width: 0;
          height: 100%;
          justify-content: center;
          padding-left: 0.5rem;

          .progress-bar-bg {
            width: 100%;
            background: #eee;
            position: relative;
            height: 0.2rem;
            cursor: pointer;
            border-radius: 0.2rem;
            margin-bottom: 0.6rem;
            margin-top: 2rem;

            span {
              content: " ";
              width: 1rem;
              height: 1rem;
              border-radius: 50%;
              background-color: #fff;
              border: 0.1rem solid #999999;
              position: absolute;
              left: 0;
              top: 50%;
              margin-top: -0.5rem;
              margin-left: -0.5rem;
              cursor: pointer;
              z-index: 2;
            }

            .progress-bar {
              background: #5a6570;
              width: 0;
              height: 0.2rem;
              // margin-top: -0.01rem;
              border-radius: 0.2rem;
              position: absolute;
              top: 50%;
              left: 0;
              transform: translateY(-50%);
              z-index: 1;
            }
          }

          .audio-time {
            width: 100%;
            height: 1.7rem;
            overflow: hidden;
            color: #999999;
            justify-content: flex-start;
            // margin-top: 0.16rem;
            line-height: 1.7rem;
            font-size: 1.2rem;
            display: flex;
            align-items: center;
          }
        }
      }
    }
  }
  .leftLocation {
    width: 3.2rem;
    height: 3.2rem;
    padding: 0.6rem;
    opacity: 0.96;
    background: #ffffff;
    border-radius: 50%;
    position: absolute;
    bottom: 12rem;
    left: 2.6rem;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 0;
    img {
      width: 2.2rem;
      height: 2.2rem;
    }
  }
}
</style>